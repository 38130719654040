html,
#root,
body {
  background-color: rgb(231, 235, 240);
}

html {
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: #00000011;
}
